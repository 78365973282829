// SHOP BANNER 

.c-shop-banner-3 {

	.c-title {
		margin: 0;
	}

	.c-desc {
		margin: 30px 0;
	}

}

// END DESKTOP

// MOBILE
@media (max-width: $screen-sm-max) {
	/* 991px */

	.c-shop-banner-3 {
	}

}