
.c-content-line-icon {
	background-image: url(../img/content/line-icons/default.png);

	&.c-white {
		background-image: url(../img/content/line-icons/white.png);
	}

	display: inline-block;
	width:60px;
	height:60px;
	background-size: cover;

	&.c-icon-screen-chart {
		background-position: 0px -1300%;
	}

	&.c-icon-support {  
		background-position: 0px -2500%;
	}				

	&.c-icon-bulb {
		background-position: 0px 1200%;  
	}

	&.c-icon-comment {
		background-position: 0px -300%; 
	}

	&.c-icon-globe {
		background-position: 0px -2600%;
	}

	&.c-icon-sticker {
		background-position: 0px  2300%;
	}

	&.c-icon-md {
		width: 40px;
		height: 40px;
	}

	&.c-icon-sm {
		width: 30px;
		height: 30px;
	}

	&.c-icon-xs {
		width: 20px;
		height: 20px;
	}	

	$per: '%';

	@for $i from 1 through 50 {
	    &.c-icon-#{$i} { 
	        background-position: 0px  #{($i - 1) * 100}#{$per};
	    }   
	}

}