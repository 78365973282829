// Card 12

// BEGIN: General Mode

.c-content-feature-7 {
	@include reset-padmar(); // call mixing reset default padding and margin
//	width: 100%;

	h3{
		color:$general-head-font-color;
	}

	h4{
		color:$general-font-color;
	}

	p{
		color:$general-font-color;
	}

  	> li {
  		display: table-cell;
    	width: 1%;

    	> .c-card {
    		padding: 50px 40px;

			> h3 {
				font-size:23px;
			}

			> h4 {
				font-size:21px;
			}

			> p {
				padding: 20px 0 40px 0; 
				font-size: 16px;
			}

    	}

    	> .c-first{
    		padding: 50px 40px 50px 0;
    	}

    	> .c-last{
    		padding: 50px 0 50px 40px;
    	}

  	}

}

// END




// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-feature-7 {
		> li {
			display: block;
			width: 100%;

			> .c-card {
				padding: 20px 20px 40px 20px;
				margin-right: 0;
				margin-bottom: 2px;

			}
		}	

	}

}

// END