.c-layout-header {
	@include clearfix();

	//	Topbar
	.c-topbar {
		@include clearfix();	

		.c-top-menu {	
			@include inline-block();
			@include reset-padmar();
			
			> .c-icons,
			> .c-links,
			> .c-ext {
				display: inline-block;
				@include reset-padmar();  
   
				list-style-type: none;
		    	
		    	> li {
		    		display: inline-block;
		    	}
			}

			> .c-icons {		    	
		    	> li {
		    		padding: 12px 5px 8px 5px; 
		    		> a {
		    			font-size: 14px;

		    			> i {
		    				font-size: 15px;
		    			}
		    		}

		    		> span {
		    			font-size: 15px;
		    		}

		    		> a {
						
		    			&:active,
		    			&:hover,
		    			&:focus {
		    				cursor: pointer;
		    				text-decoration: none;		    			
		    			}
		    		}
		    	}
			}

			> .c-links {		    	
		    	> li {
		    		padding: 0 3px 5px 3px; 

		    		> a:not(.btn),
		    		> span {
		    			font-size: 15px;
		    			> i {
		    				font-size: 16px;
		    			}
		    		}

		    		> a:not(.btn) {						
		    			&:active,
		    			&:hover,
		    			&:focus {
		    				cursor: pointer;
		    				text-decoration: none;		    			
		    			}
		    		}

		    		&.c-divider {
		    			position: relative;
		    			font-size: 13px;
		    			top: -1px;
		    		}
		    	}
			}

			> .c-ext > li {
				margin: 0;
				padding: 0;

				> a > i {
					font-size: 15px;
					position: relative;
					top: 3px;
				}

				&.c-lang {
					position: relative;
					top: 8px;
					margin: 0 10px 0 15px;

					&.c-last {
						margin-right: 0;
					}

					> a {		
						display: inline-block;		
						float: left;
						padding: 7px 7px 5px 7px;
						height: 28px;
						font-size: 12px;
						font-weight: 500;
						text-transform: uppercase;
						letter-spacing: 1px;
					}

					> .dropdown-menu {
						@include border-radius(0);
						min-width: 155px;
						padding: 0;
						margin:0;

						> li {
							padding: 0;
							> a {	
								letter-spacing: 1px;
								font-size: 12px;
								font-weight: 500;
								text-transform: uppercase; 
								padding: 8px 12px; 	
							}
						}
					}

					&:hover {
						> .dropdown-menu {
							display: block;
						}
					}
				}

				&.c-search {
					margin-top: 8px;

					> form {
						float: left;
						padding: 0;
						margin: 0;
						position: relative;

						> .form-control {
							font-weight: 400;
							border: 0;
							border-radius: 0;
							padding: 4px 14px 2px 6px;
							height: 28px;
							font-size: 12px;
							text-transform: uppercase;
						}

						> i {
							cursor: pointer;
							font-size: 13px;
							position: absolute;
							top: 8px;
							right: 8px;
						}
					}
				}
			}
		}
	}
}

// END

@media (min-width: $screen-md-min) { /* 992px */
	.c-layout-header {
		// Topbar 
		.c-topbar {
			height: $topbar_height;

			> .container-fluid {
				padding: $layout-fluid-padding;
			}
		}
	}

	.c-page-on-scroll.c-layout-header-fixed .c-layout-header {
		//	Topbar
		.c-topbar { 
			transition: all $header_transition;
			display: none;
		}
	}
}

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header {
		//	Topbar
		.c-topbar {	
			margin: 0;
			min-height: $topbar_height;

			.container-fluid {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	// Fixed header for mobile
	.c-layout-header-topbar-collapse .c-layout-header {  
		.c-topbar {
			display: none;
		}
	}

	.c-layout-header-topbar-collapse.c-topbar-expanded .c-layout-header {
		.c-topbar {
			display: block;
		}
	}

	.c-layout-header-topbar-collapse.c-topbar-expanded.c-layout-header-fixed.c-layout-header-mobile-fixed:not(.c-layout-header-fullscreen) .c-layout-page {
  		margin-top: $topbar_height + $header_mobile-height;  
	}
}      