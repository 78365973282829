// BEGIN: Media 2

.c-content-v-center {

	.c-wrapper {
		width: 100%;
	  	height: 100%;
	  	display: table;

	  	.c-body {
  			display: table-cell;
  			vertical-align: middle;
	  	}
	}
}

// END: Media 2