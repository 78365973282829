// STEPS 3

.c-content-step-3{
	
	.c-steps-3-block{

		i {
		    font-size: 30px;
		    border: solid 2px #fff;
		    border-radius: 30px;
		    width: 60px;
		    height: 60px;
		    text-align: center;
		    vertical-align: middle;
		    padding-top: 13px;
		    margin-right: 11px;
		    float: left;
		}

		h2{
			margin-top:15px;
		}

		span{
			width: 36px;
			height: 71px;
			background: url(../img/content/misc/step3-angle-right.png) no-repeat 100% 50%;
			position: absolute;
			top: 0;
			right: 10px;
		}

		> .c-steps-3-title{
			padding-right:10%;
			padding-left:71px;
		}

	}
}

// END

// MOBILE
@media (max-width: $screen-sm-max) {
	.c-content-step-3{
		padding:15px;

		.c-steps-3-block{
			padding:15px;
			border:1px solid;
			border-color:rgba(255,255,255,0.7);
			margin-bottom:20px;

			&:last-child{
				margin-bottom:0;
			}
		}

		span{
			display:none;
			background:none;
		}

	}
}