// Header 
$layout_full_page:                                                              'no';  // yes or no
$header_class:																	'2';
$header_desktop-bg-color:														rgba(#1e242a, 0.25);
$header_desktop-navbar-bg:														none;
$header_desktop_border:															0;
$header_min_desktop_border:														0;
$header_min_desktop_shadow:														none;

$brand-link-font-color:  														#ebedf2;
$brand-link-font-color-on-hover:  												#ff6b57;

// Mega Menu
$mega-menu_desktop_primary-link-font-color:										#ffffff; 
$mega-menu_desktop_primary-link-font-color-on-hover:							#ffffff;
$mega-menu_desktop_primary-link-font-color-on-active:							#ffffff;

$mega-menu_desktop_min-header-bg-color:											rgba(#1e242a, 0.82);
$mega-menu_desktop_min-header-primary-link-font-color:							#ffffff;  

@import  'theme/_header';