@mixin radio-variant($box, $check, $label) {
    label {
      color: $label;
    }

    label > .box {
      border-color: $box;
    }

    label > .check {
      background: $check;
    } 
}

@mixin radio-label-variant($color) {
    > label {
      color: $color;     
    }   
}


.c-radio { 
    position:relative;

    input[type=radio]   { 
        visibility: hidden;
        position: absolute;   
    }

    label {   
        line-height: 1px;
        cursor:pointer; 
        padding-left: 30px;  
    }

    label > span { 
        display:block;    
        position:absolute; 
        left:0; 
        -webkit-transition-duration:0.3s; 
        -moz-transition-duration:0.3s; 
        transition-duration:0.3s;
    }

    label > span.inc   { 
      background: #fff;
      left:-10px;  
      top:-10px;
      height:40px;
      width:40px;
      opacity:0; 
      border-radius:50% !important;
      -moz-border-radius:50% !important;
      -webkit-border-radius:50% !important;
    }

    label > .box {
      top: 1px;
      border:2px solid $input-checkbox-box-color;
      height:20px;   
      width:20px;
       border-radius:50% !important;
      -moz-border-radius:50% !important;
      -webkit-border-radius:50% !important;
      z-index:5;

      .ie & {
          top: 2px;
      }
    }

    label > .check {
      top: 6px;
      left: 5px; 
      width: 10px;
      height: 10px;
      background: $input-focus-border;  
      opacity:0; 
      z-index:6; 
      display: block; 

      border-radius:50% !important;
      -moz-border-radius:50% !important;
      -webkit-border-radius:50% !important;

      -webkit-transform   :scale(0);
      -moz-transform      :scale(0);
      transform           :scale(0);
    }

    /* handling click events */

    /* when radio is checked */
    label > span.inc {
      -webkit-animation   :growCircleRadio 0.3s ease;
      -moz-animation      :growCircleRadio 0.3s ease;
      animation           :growCircleRadio 0.3s ease;   
    }

    input[type=radio]:checked ~ label > .check {
      opacity:1; 
      -webkit-transform   :scale(1);
      -moz-transform      :scale(1);
      transform           :scale(1);
    }

    // Disabled state
    input[type=radio]:disabled ~ label,
    input[type=radio][disabled] ~ label {
        cursor: not-allowed;   
        @include opacity($input-checkbox-disabled-opacity); 
    }
   
    input[type=radio]:disabled ~ label > .box,
    input[type=radio][disabled] ~ label > .box { 
        cursor: not-allowed;
        @include opacity($input-checkbox-disabled-opacity);
    }

    input[type=radio]:disabled:checked ~ label > .check, 
    input[type=radio][disabled]:checked ~ label > .check {           
        cursor: not-allowed;
        @include opacity($input-checkbox-disabled-opacity);
    }

    // Error states
    &.has-error {
        @include radio-variant($brand-danger, $brand-danger, $brand-danger);
    }
  
    &.has-info {
        @include radio-variant($brand-info, $brand-info, $brand-info);
    }

    &.has-success {
        @include radio-variant($brand-success, $brand-success, $brand-success);
    }

    &.has-warning {
        @include radio-variant($brand-warning, $brand-warning, $brand-warning);
    }
}

.form-c-radios {
    > label {  
        
    }  

    &.has-error {
        @include radio-label-variant($brand-danger);
    }

    &.has-info {
        @include radio-label-variant($brand-info);
    }

    &.has-success {
        @include radio-label-variant($brand-success);
    }

    &.has-warning {
        @include radio-label-variant($brand-warning);
    }
}

.c-radio-list {
  margin: 10px 0;

  .form-horizontal & {
    margin-top: 0px;    
  }

  .c-radio {
    display: block;
    margin-bottom: 10px;
  }
}  

.c-radio-inline {
  margin: 10px 0;

  .form-horizontal & {
    margin-top: 8px;    
  }

  .c-radio {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
     
/* bubble animation */

@-webkit-keyframes growCircleRadio {
  0%, 100%   { -webkit-transform:scale(0); opacity: 1}
  70%        { background:#eee; -webkit-transform:scale(1.25); }
}
@-moz-keyframes growCircleRadio {
  0%, 100%   { -moz-transform:scale(0); opacity: 1}
  70%        { background:#eee; -moz-transform:scale(1.25); }
}
@keyframes growCircleRadio {
  0%, 100%   { transform:scale(0); opacity: 1}
  70%        { background:#eee; transform:scale(1.25); }
}