//* Base Header *//

// Settings

// BEGIN: Header
$header_zindex:			 														$zindex-navbar;
$header_transition: 															0.2s;
$header_height:                                                   				100px; 
$header_on-scroll-height:                                         				65px;  
$topbar_height:																	45px;

// Desktop mode
$header_desktop_brand-margin: 													25px 0 37px 0;
$header_desktop_mega-menu-primary-link-padding: 								41px 15px 39px 15px; 
$header_desktop_mega-menu-btn-margin: 											34px 15px 33px 15px; 
$header_desktop_mega-menu-btn-icon-margin: 										24px 5px 20px 5px;
$header_desktop_mega-menu-quick-sidebar-toggler-padding:						44px 0 40px 15px; 
$header_desktop_mega-menu-dropdown-link-font-size:                              14px;
$header_desktop_mega-menu-dropdown-link-uppercase-font-size:                    12px;

$header_desktop_mega-menu-quick-search-close-icon-top:							50px;
$header_desktop_mega-menu-quick-search-close-icon-right:						-2px;
$header_desktop_mega-menu-quick-search-form-control-padding:					10px 0;

$header_desktop_min-header-brand-margin:										12px 0 19px 0;
$header_desktop_min-header-mega-menu-primary-link-padding: 						23px 15px 21px 15px;
$header_desktop_min-header_mega-menu-btn-margin: 								15px 15px 16px 15px; 
$header_desktop_min-header_mega-menu-btn-icon-margin: 							6px 5px 7px 5px;
$header_desktop_min-header_mega-menu-quick-sidebar-toggler-padding:				26px 0 24px 15px; 

$header_desktop_min-header-mega-menu-quick-search-close-icon-top:				33px;
$header_desktop_min-header-mega-menu-quick-search-form-control-padding:			10px 0;

// END  

@import '_mixins'; 

// BEGIN: General mode

.c-layout-header {
	@include clearfix();

	//	Navbar
	.c-navbar {
		@include clearfix();		
	}

	// Header brand
	.c-brand {
		transition: all $header_transition;
			
		@include inline-block();

		> .c-hor-nav-toggler { 
			display: none;
		}

		> .c-topbar-toggler,
		> .c-search-toggler,
		> .c-cart-toggler {
			display: none;  
		}

		// hide when full view quick search is shown 
		.c-layout-quick-search-shown & {
			display: none;
		}

		// logo
		.c-desktop-logo {
			display: block;
		}
				
		.c-desktop-logo-inverse {
			display: none;
		}

		> .c-btn-login {
			display: none;
		}
	}

	// Quick search
	.c-quick-search {
		display: none;
		padding: 0;
		margin: 0;
		transition: all $header_transition;  
		position: relative;

		@include clearfix();

		// search shown state
		.c-layout-quick-search-shown & {
			display: block;
			transition: all $header_transition;
		}
				
		> .form-control {
			display: block;
			font-size: 22px;
			font-weight: 400;
			border: 0;				
			background: transparent;
			box-shadow: none;
			@include border-radius(0);

			// remove IE10+ clear icon
			&::-ms-clear {
	    		display: none;
			}

			&:focus,
			&:active {
				box-shadow: none;
			}
		}

		> span {
			display: inline-block;
			position: absolute;
			font-size: 36px;
			font-family: $general-font-family;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

// END

// BEGIN: Desktop mode

@media (min-width: $screen-md-min) { /* 992px */ 
	// Base header
	.c-layout-header {

		&.c-no-border {
			border-bottom: 0 !important;
		}

		//	Navbar
		.c-navbar {
			line-height: 0px;
			// reset container position for mega menu support
			> .container {
				position: relative;
			}

			> .container-fluid {
				position: relative;
				padding: $layout-fluid-padding;
			}	
		}

		// Header brand
		.c-brand {
			margin: $header_desktop_brand-margin;

			.c-desktop-logo-inverse {
				display: none;
			}

			.c-desktop-logo {
				display: inline-block;
			}

			.c-mobile-logo {
				display: none;
			}
		}

		// Quick search
		.c-quick-search {
			> .form-control {
				padding: $header_desktop_mega-menu-quick-search-form-control-padding;
				height: $header_height;
			}

			> span {
				top: $header_desktop_mega-menu-quick-search-close-icon-top;
				right: $header_desktop_mega-menu-quick-search-close-icon-right;
			}
		}
	}

	// Fixed header
	.c-layout-header-fullscreen.c-layout-header-static .c-layout-header,
	.c-layout-header-fixed .c-layout-header {     
		top: 0;
		position: fixed;
		z-index: $header_zindex;
		width: 100%;
	}	

	.c-layout-header-fixed .c-layout-page {
		margin-top: $header_height;
	}

	.c-layout-header-fixed.c-layout-header-topbar .c-layout-page {
		margin-top: $header_height + $topbar_height;
	}

	.c-layout-header-fullscreen .c-layout-page,
	.c-layout-header-fullscreen.c-layout-header-fixed.c-layout-header-topbar .c-layout-page {
		margin-top: 0;
	}

	// Minimized mode on scroll
	.c-page-on-scroll.c-layout-header-static .c-layout-header {
		display: none;
	}

	.c-page-on-scroll.c-layout-header-fixed .c-layout-header {
		height: $header_on-scroll-height; 
		line-height: 0px;
		
		// Brand
		.c-brand {
			transition: margin $header_transition,width $header_transition;
			margin: $header_desktop_min-header-brand-margin;

			// logo
			.c-desktop-logo {
				display: none;
			}

			.c-desktop-logo-inverse {
				display: block;
			}
		}

		// Horizontal Navigation bar
		.c-mega-menu {		
			line-height: 0px;	
			
			.nav.navbar-nav > li {
				
				// link
				> .c-link {
					transition: all $header_transition;  
					padding: $header_desktop_min-header-mega-menu-primary-link-padding;
					min-height: $header_on-scroll-height;
				}

				// Quick sidebar toggler

				> .c-quick-sidebar-toggler {
					transition: all $header_transition;  
					padding: $header_desktop_min-header_mega-menu-quick-sidebar-toggler-padding;
				}

				// button
				> .c-btn {
					transition: all $header_transition;
					margin: $header_desktop_min-header_mega-menu-btn-margin;
				}

				// icon
				> .c-btn-icon {
					transition: all $header_transition;
					margin: $header_desktop_min-header_mega-menu-btn-icon-margin;
				}		
			}		
		}

		// Quick search form
		.c-quick-search {
			> .form-control {
				font-size: 22px;
				padding: $header_desktop_min-header-mega-menu-quick-search-form-control-padding;
				height: $header_on-scroll-height;
			}

			// close icon
			> span {
				font-size: 36px;
				top: $header_desktop_min-header-mega-menu-quick-search-close-icon-top;
			}
		}
	}
}

// END

