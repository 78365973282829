// Top Menu
$topbar_class:																'dark';
$topbar_bg-color:															none;
$topbar_collapse-bg-color:													#ffffff;
$topbar_border:																1px solid rgba(#ffffff, 0.15);
$topbar_font-color: 														#ffffff;
$topbar_font-color-on-hover: 												darken($topbar_font-color, 20%);

$topbar_icon-font-color:													darken(#ffffff, 3%);
$topbar_icon-font-color-on-hover:											darken($topbar_icon-font-color, 20%);

$topbar_language-bar-bg-color:												rgba(#ffffff, 0.2);
$topbar_language-bar-bg-color-on-hover:										$topbar_language-bar-bg-color;  
$topbar_language-bar-font-color:                                            darken($topbar_font-color, 5%);
$topbar_language-bar-font-color-on-hover:                                   darken($topbar_language-bar-font-color, 10%); 

$topbar_language-menu-bg-color: 											#f6f8fa;
$topbar_language-menu-link-font-color:										darken($topbar_language-menu-bg-color, 70%);
$topbar_language-menu-link-font-color-on-hover:								darken($topbar_language-menu-bg-color, 40%); 
$topbar_language-menu-link-bg-color-on-hover:								darken($topbar_language-menu-bg-color, 4%);   

@import  'theme/_topbar';       