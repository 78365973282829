// ISOTOP GRID

.c-content-isotope-grid{

	.c-content-isotope-item{
		height:auto;
		position: relative;
		overflow: hidden;

		>.c-content-isotope-image-container{
			position: relative;
			overflow: hidden;

			.c-content-isotope-image{
				width:100%;
				height: auto;
				@include transition(all .3s ease-in-out);
			}

			>.c-content-isotope-overlay{
				position: absolute;
				width: 100%;
				height: 100%;
				padding:30px;
				background-color: rgba(0,0,0,0.7);
				top:0;
				left:0;
				opacity: 0;
				@include transition(all .3s ease-in-out);

				>.c-content-isotope-overlay-content{
					text-align: center;

					>.c-content-isotope-overlay-title{
						margin-top:0;
						font-size:18px;
						font-weight: 600;
					}
					>.c-content-isotope-overlay-desc{
						font-size:14px;
					}
					>.c-content-isotope-overlay-price{
						font-size:28px;
					}
				}
			}

			&:hover{
				>.c-content-isotope-overlay{
					opacity: 1;
				}
			}
		}
	}

	&.c-opt-1{
		margin:-10px;
		.c-content-isotope-item{
			width:25%;
			padding:10px;

			&.c-item-size-double{
				width:50%;
			}

			.c-content-isotope-image-container{
				padding:7px;
				background-color: rgba(0,0,0,0.1);

				>.c-content-isotope-overlay{
					width:auto;
					height:auto;
					left:7px;
					right:7px;
					top:auto;
					bottom:-100%;
				}

				&:hover{
					>.c-content-isotope-overlay{
						bottom:7px;
					}
				}
			}
		}
	}

	&.c-opt-2{
		margin:-10px;
		.c-content-isotope-item{
			width:25%;
			padding:10px;

			&.c-item-size-double{
				width:50%;
			}

			.c-content-isotope-image-container{
				padding:7px;
				background-color: rgba(0,0,0,0.1);

				.c-content-isotope-overlay-content{
					width: 80%;
					position: absolute;
					top:50%;
					left:50%;
					transform:translateY(-50%) translateX(-50%);
				}
			}
		}
	}

	&.c-opt-3{
		margin:-10px;

		.c-content-isotope-item{
			width:25%;
			padding:10px;

			&.c-item-size-double{
				width:50%;
			}

			.c-content-isotope-image-container{
				padding:7px;
				background-color: #fff;
				border:1px solid #ccc;
				width:100%;

				.c-content-isotope-overlay-content{
					width: 80%;
					position: absolute;
					top:50%;
					left:50%;
					transform:translateY(-50%) translateX(-50%);

					>.c-content-isotope-overlay-btn{
						margin: 10px 0 0 0;

						&:first-child{
							margin-top:0;
						}
					}
				}

				&:hover{
					box-shadow: 0px 0px 15px #000;

				}
			}
		}
	}
}


@media (max-width: $screen-sm-max){ // 991px

	.c-content-isotope-grid{
		&.c-opt-1,
		&.c-opt-2,
		&.c-opt-3{
			.c-content-isotope-item{
				width:50%;

				&.c-item-size-double{
					width:100%;
				}
			}
		}
	}
}

@media (max-width: $screen-xs-min){ // 480px;

	.c-content-isotope-grid{

		&.c-opt-1,
		&.c-opt-2,
		&.c-opt-3{
			.c-content-isotope-item{
				width:100%;
			}
		}
	}

}

