// LABEL 1

.c-content-label {
	padding: 4px 10px 2px 10px;
	color:#ffffff;
	background-color: #32c5d2;
	font-weight: 400;
	font-size:16px;
	display: inline-block;	

	&.c-font-uppercase {
		font-size: 14px;
	}

	&.c-label-sm {
		font-size: 13px;
		padding: 6px 12px 4px 12px;

		&.c-font-uppercase {
			font-size: 14px;
		}
	}

	&.c-label-lg {
		font-size: 18px;
		padding: 6px 14px 4px 14px;

		&.c-font-uppercase {
			font-size: 20px;
		}
	}
}

// END 