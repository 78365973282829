.c-content-pagination {
	display: inline-block;
	padding: 0;
	margin: 0;

	> li {
		list-style: none;
		display: inline-block;

		> span,
		> a {
			width: 40px;
			height: 40px;
			text-align: center;
			display: inline-block;
			font-size: 17px;
			color: $general-font-color;
			font-weight: 400;
			padding: 7px 4px 6px 4px;
			border-radius: 40px;
			border: 1px solid darken($general-border-color, 0);
		}

		&.c-space {
			> span {
				border-radius: 0;
				border: 0;
			}
		}

		&:hover {
			> a {

			}
		}

		&.c-active {
			> span,
			> a {

			}
		}
	}

	&.c-square {
		> li {
			> span,
			> a {
				border-radius: 0;
			}
		}
	}
}