// Feature 3
 
// BEGIN: General Mode

.c-content-feature-3 {
	padding: 0;
	margin-bottom: 70px;
	text-align: center;

	> p {
		color:#ffffff;
		font-size:17px;
		line-height: 25px;
		font-weight: 500;
		padding:40px;
		margin: 0;
	}

	> .btn {
		margin-top: 5px;
	}
}

.c-content-feature-3-grid {
	text-align: center;

	> .c-title {
		text-align: center;
		color: #ffffff;
		font-weight: 500;
		font-size: 40px;
		margin: 100px auto 20px auto;
		display: inline-block;
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-feature-3 {
		margin-bottom: 40px;

		> p {
			padding:20px;
		}

		> .btn {
			margin-top: 5px;
		}
	}

	.c-content-feature-3-grid {

		> .c-title {
			margin: 70px auto 10px auto;
		}
	}

}