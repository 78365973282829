.c-content-overlay {
	overflow: hidden;
  	position: relative;

  	.c-overlay-object {
  		position: relative;
  		z-index: 1;
  	}

	.c-overlay-wrapper {
		@include clearfix();
		@include opacity(0);
		position: absolute;
		top: -100%;
		left: 0;
		width: 100%;
	  	height: 100%;
	  	z-index: 3;

	  	transition: all 0.2s;

	  	&:before {
	  		display: inline-block;
	  		content: '';
	  		vertical-align: middle;
	 		height: 100%;
	 	}

		background: rgba(0,0,0,0.4);

		.c-overlay-content {
			text-align: center;
			display: inline-block;
		  	vertical-align: middle;
		  	padding: 0;
		  	height: auto;
		  	width: 95%;

		  	i {
		  		display: inline-block;
		  		width: 44px;
		  		height: 44px;
		  		border-radius: 40px;
	  			text-align: center;
			  	padding: 14px 6px 8px 6px;
			  	color: #fff;
			  	font-size: 16px;
			  	background: #000000;
			  	@include opacity(0.7);
			  	margin: 0 5px;

			  	&:hover {
			  		@include opacity(1);
			  	}
			}
		}

		// overlay options
		&.c-overlay-padding {
			left: 10px;
			width: calc(100% - 20px);
			height: calc(100% - 20px);
		}
	}

	&:hover {
  		cursor: pointer;

  		.c-overlay-wrapper {
  			top: 0;
  			@include opacity(1);

		    // overlay options
		    &.c-overlay-padding {
			    top: 10px;
		    }
  		}
  	}

	.c-overlay-border {
		border: 1px solid rgba(255, 255, 255, 0.2);
		top: 10px;
		bottom: 10px;
		left: 10px;
		right: 10px;
		position: absolute;
		z-index: 1;
	}

}
