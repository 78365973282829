// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header.c-layout-header-#{$header_class} {
		background: $header_mobile-bg-color; 
		border-bottom: $header_mobile_border;

		// Header Brand 
		.c-brand {		
			> .c-hor-nav-toggler { 
				position: relative;
				top: 8px;
				display: inline-block;
				background: $brand_mobile_toggler_bg-color;
				padding: 5px 8px 6px 8px;

				> .c-line { 
					background: $brand_mobile_toggler_line_bg-color;
				}

				&:hover {
					background: darken($brand_mobile_toggler_bg-color, 5%);

					> .c-line { 
						background: darken($brand_mobile_toggler_line_bg-color, 5%);
					}
				}
			}

			> .c-topbar-toggler,
			> .c-search-toggler,
			> .c-cart-toggler { 
				> i {
					color: $brand_mobile_link-font-color;
				}

				&:hover {
					> i {
						color: $brand_mobile_link-font-color-on-hover;
					}
				}
			}
		} 

		// Quick search
		.c-quick-search {
			> .form-control {
				@include placeholder($quick-search_mobile_input-placeholder-color);  
				color: $quick-search_mobile_input-font-color;
			}

			// close icon
			> span {
				color: $quick-search_mobile_icon-font-color;
			}
		}
	}  

	// Fixed header for mobile
	.c-layout-header-fullscreen.c-layout-header-mobile-fixed.c-layout-header-static .c-layout-header,
	.c-layout-header-fixed.c-layout-header-mobile-fixed .c-layout-header {  
		border-bottom: $header_min_mobile_border;
		box-shadow: $header_min_mobile_shadow; 
	}
}

// END