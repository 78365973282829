// BEGIN: Mega Menu

$mega-menu_class:																'dark';

// Desktop Mode
$mega-menu_desktop_separator-border-color:										#32373d;
$mega-menu_desktop_primary-dropdown-menu-bg-color:                  			#2c3137;
$mega-menu_desktop_primary-dropdown-menu-box-shadow:							none;
$mega-menu_desktop_primary-dropdown-menu-border:								0; 

$mega-menu_desktop_inner-dropdown-menu-head-color:         						#cbd2d9;
$mega-menu_desktop_inner-dropdown-menu-link-color:                  			#7d8691;   
$mega-menu_desktop_inner-dropdown-menu-link-color-on-active:        			#ff6b57;
$mega-menu_desktop_inner-dropdown-menu-link-bg-color-on-active:     			#292e33;

$mega-menu_desktop_inner-tab-link-color:                   						#cbd2d9;   
$mega-menu_desktop_inner-tab-link-color-on-active:         						#ffffff;
$mega-menu_desktop_inner-tab-border-color:										#434b53;

@import  'theme/mega-menu';