// SHOP ORDER COMPLETE 1

.c-shop-order-complete-1 {
	@include reset-padmar();

	.c-message {
		padding: 30px 10px;
	}

	.c-order-summary {
		padding: 0 20%;
	}

	.c-row-item {
		padding: 30px 0;
	}

	.c-row-total {
		margin-right: 30px;
	}

	.c-list {
		li + li {
			margin-top: 10px;
		}
	}

	.c-list-inline {
		li + li {
			margin-left: 20px;
		}
	}
}

// END

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-order-complete-1 {

		.c-order-summary {
			@include reset-padmar();
		}

		.c-image {
			padding-bottom: 30px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */
	.c-shop-order-complete-1 {
	}
}