//BEGIN: Tab 5
.c-content-tab-5 {

	.c-nav-tab {
		text-align: center;
		margin-bottom: 45px;

		> li {
			display: inline-block;
			float: none;
			margin-bottom: 15px;

			> a {
				background-color: #eff4f6;
				border-radius: 0;
				padding: 10px 20px;
			}

			& + li {
				margin-left: 0px;
			}
		}

		&.c-arrow {
			a {
				position: relative;

				&:after {
					left: 0;
					top: 80%;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					margin-top: 0;
					border: 8px solid transparent;
				}

				&:hover {
					&:after {
						-webkit-transition: back 0.4s ease-out;
						-moz-transition: all 0.4s ease-out;
						-o-transition: all 0.4s ease-out;
						-ms-transition: all 0.4s ease-out;
					}
				}
			}
		}
	}

	.c-nav-tab {
		> li {
			&.active > a,
			a:hover {
				color: #fff;
				background-color: #d05163;
			}
		}

		&.c-arrow {
			a:hover {
				&:after {
					border-left-color: #d05163;
				}
			}

			.active > a {
				&:after {
					border-left-color: #d05163;
				}
			}
		}
	}

	.c-space {
		margin: 15px 0;
	}

}

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) {
	/* 991px */

	.c-content-tab-5 {

	}

}

@media (max-width: $screen-xs-max) {
	/* 766px */

	.c-content-tab-5 {

		.c-no-left {
			padding-left: 15px;
		}

		.c-no-right {
			padding-right: 15px;
		}

	}

}

//END Mobile Mode

//END: Tab 5
