.c-content-ver-nav {

	.c-menu {
		margin: 0;
		padding: 0;

		> li {
			list-style: none;
			padding: 7px 0; 
			border-bottom: 1px solid #f2f2f2;

			> a {
				font-weight: 400;

				> .badge {
					float: right;
				}
			}

			&:before {
				color: $general-font-color;
				content: "\f105";
				font-family: FontAwesome;
  				font-weight: 100;
				width: 12px;
			  	height: 12px;
			  	font-size: 14px;
				float:left;
				margin: 2px 5px 0 0;
			}  
		}

		&.c-arrow-dot {
			> li {
				&:before {
					width: 8px;
					height: 8px;
					border-radius: 8px;
					margin: 9px 12px 0 0;
					content: '';
					@include opacity(0.6);
					background: $general-font-color;
				}
			}

			&.c-square {
				> li:before {
					border-radius: 0;
				}
			}
		}
	}
}