// SUBSCRIBE FORM 1

// BEGIN: General Mode

.c-content-subscribe-form-1 {
	padding: 5px 0 13px 0;

	.c-title {
		color: #ffffff;
		font-size: 22px;
		font-weight: 400;
		padding-bottom: 10px;
	}

	.c-body {
		color: #6a7887;
	}

	form {
		padding-top: 30px;
	}

	.input-group {

		> .form-control {
			color: lighten(#58626d, 10%);
			font-size: 17px;
			font-weight: 300;  
			border-radius: 0;
			box-shadow: none;
			border-color: #41464c;
			background: transparent;
			padding: 8px 16px;
			@include placeholder(#58626d);

			&:focus {
				box-shadow: none;
				border-color: #474f59;
			}
		}

		.btn {
			padding-left: 30px;
			padding-right: 30px;
			border-radius: 0;
			border: 0;
		}
	}

	&.c-subscribe-form-light {
		.c-title {
			color: $general-head-font-color;
		}

		.c-body {
			color: $general-font-color;
		}		

		.input-group {

			> .form-control {
				background: #ffffff;
				color: $general-font-color;
				border-color: transparent;
				@include placeholder(lighten($general-font-color, 10%));

				&:focus {
					border-color: transparent;
				}
			}
		}	
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) {
	/* 991px */

}

// END