// SHOPPING FILTER SEARCH 1

.c-shop-filter-search-1 {
	li + li {
		margin-top: 30px;
	}

	.c-checkbox label {
		> .box {
			top: 4px;
			height: 15px;
			width: 15px;
		}

		> .check {
			top: 2px;
			left: 4px;
			width: 8px;
			height: 15px;
		}

		> .inc {
			left: -12px;
			top: -8px;
		}
	}

	.c-review-star {
		margin: 0;
	}

	.c-checkbox-height {
		height: 25px;
	}

	.c-price-range-box {
		width: 100%;
		.c-price {
			width: 50%;
		}
	}

	.c-price-range-slider {

		&.c-theme-1 {
			.slider {
				.slider-selection {
					background: #32c5d2;
				}
				.slider-handle {
					background: #27A8B4;
				}

			}
		}

		&.c-theme-2 {
			.slider {
				.slider-selection {
					background: #e7505a;
				}
				.slider-handle {
					background: #E22C38;
				}
			}
		}
	}
}

// END

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-filter-search-1 {
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */
	.c-shop-filter-search-1 {
	}
}