//	BEGIN: General Mode

.c-layout-revo-slider-7 {
	.c-singup-form {
		display: none;
		text-align: center;
		width: 100%;
		position: absolute;  
		z-index: 100;
		top: 50%;
		margin-top: -100px;

		.c-subtitle {
			display: inline-block;
			padding: 12px 0 8px 0;
			border-top: 1px solid rgba(#ffffff, 0.3);
			border-bottom: 1px solid rgba(#ffffff, 0.3);  
		}

		.form-inline {
			margin-top: 20px;

			.form-group {
				margin-right: 5px;

				.form-control {
					border-color: #fff;
					color: #fff;  

					@include placeholder(darken(#ffffff, 5%));
				}
			}			
		}
	}
}

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-revo-slider-7 {
		.c-singup-form {	
			top: auto;	
			margin-top: 200px;

			.c-layout-header-mobile-fixed & {
				margin-top: 300px;
			}

			h3 {
				font-size: 26px;
				font-weight: bold;
			}

			h5 {
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
}

@media (max-width: $screen-xs-max) { /* 768px */
	.c-layout-revo-slider-7 {
		.c-singup-form {
			margin-top: 50px;

			h3 {
				font-size: 17px;
				font-weight: bold;
			}

			h5 {
				font-size: 14px;
				font-weight: bold;
			}

			.c-layout-header-mobile-fixed & {
				margin-top: 150px;
			}

			.form-inline .form-group {
				margin: 10px 10px;
			}
		}
	}
}

// END

