// FAQ 2

.c-page-faq-2{

	.c-faq-tabs{
		border: 1px solid;
		border-color:#ddd;

		>li {
			float:none;

			>a{
				border:none;

				&:hover,
				&:focus{
					border:none;
				}
			}

			&.active{
				background-color:#eee;

				> a{
					background-color: transparent;
					color:#32c5d2;	
				}
			}
		}
	}

	.c-content-accordion-1{

		.panel {
			border-bottom:1px solid;
			border-color:#eee;
			margin:0;

			&:last-child{
				border:none;
			}

			> .panel-heading{

				> .panel-title{

					>a{
						color:#3f444a;
						padding:10px;
						font-size:19px;

						> i{
							width: 17px;
						}

						&.collapsed{
							color:#3f444a;
						}
					}
				}
			}

			>.panel-collapse{

				>.panel-body{
					color:#3f444a;
					padding-bottom: 20px;
					padding-left: 35px;
					font-size: 15px;
				}
			}
		}
	}
}

// END 

// MOBILE

@media (max-width: $screen-sm-max) {

	.c-page-faq-2{

		.c-content-title-1{
			margin-top:40px;
		}
	}
}