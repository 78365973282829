.c-content-tab-1 {
	
	.nav,
	.nav.nav-justified {
		border: 0;
		border-bottom: 3px solid none;

		> li {

			> a {
				color: $general-head-font-color;
				border: 0;
				border-bottom: 3px solid none;
			}

			&:hover,
			&:focus,
			&:active, 
			&.active {
				background: none;

				> a {
					background: none;
					transition: none;
					border: 0;
					border-bottom: 3px solid #32c5d2;
				}
			}
		}

		&.tabs-left {
			border: 0;
			border-right: 1px solid $general-border-color;

			> li {

				> a {
					border: 0;
					border-right: 3px solid transparent;
				}

				&:hover,
				&:focus,
				&:active, 
				&.active {
					background: $general-bg-color;

					> a {
						border-radius: 0;
						background: none;
						transition: none;
						border: 0;
						border-right: 3px solid #32c5d2;
					}
				}
			}

			> li:last-child {
				> a {
					border-bottom: 0;
				}
			}
		}

		&.tabs-right {
			border: 0;
			border-left: 1px solid $general-border-color;  

			> li {

				> a {
					border: 0;
					border-left: 3px solid transparent;
				}

				&:hover,
				&:focus,
				&:active, 
				&.active {
					background: $general-bg-color;

					> a {
						border-radius: 0;
						background: none;
						transition: none;
						border: 0;
						border-left: 3px solid #32c5d2;
					}
				}
			}

			> li:last-child {
				> a {
					border-bottom: 0;
				}
			}
		}

		&.tabs-below {
			border: 0;
			border-top: 0;  

			> li {

				> a {
					border: 0;
					border-top: 3px solid transparent;
				}

				&:hover,
				&:focus,
				&:active, 
				&.active {
					background: none;

					> a {
						border-radius: 0;
						background: none;
						transition: none;
						border: 0;
						border-top: 3px solid #32c5d2;
					}
				}
			}
		}
	}

	.tab-content {
		padding: 0;

		&.c-bordered {
			border: 1px solid $general-border-color;
		}

		&.c-left-bordered {
			border-left: 1px solid $general-border-color;
		}

		&.c-right-bordered {
			border-right: 1px solid $general-border-color;
		}

		&.c-padding-sm {
			padding: 5px;
		}

		&.c-padding {
			padding: 15px;
		}

		&.c-padding-lg {
			padding: 30px;
		}
	}

}