//BEGIN: Testimonal 4
.c-content-testimonials-4 {

	// override from owl theme
	.owl-theme .owl-controls .owl-buttons div {
		background: url(../img/content/line-icons/grey1.png);
		display: inline-block;
		width: 40px;
		height: 40px;
		background-size: cover;

		&.owl-prev {
			background-position: 0px 3600%;
			left: -120px;
			position: absolute;
		}

		&.owl-next {
			background-position: 0px 3700%;
			right: -120px;
			position: absolute;
		}
	}

	&.c-icon-white {
		.owl-theme .owl-controls .owl-buttons div {
			background-image: url(../img/content/line-icons/white.png);
		}
	}

	.owl-buttons {
		position: absolute;
		top: 115px;
		left: 50%;
	}

	.c-content {
		text-align: center;
		padding: 0 150px;
	}

	.c-person {
		padding-top: 27px;

		> .c-person-detail {
			text-align: center;
			padding-top: 23px;
		}

		> img {
			border-radius: 50%;
			width: 120px;
			min-width: 120px;
			max-width: 120px;
			margin-left: auto;
			margin-right: auto;
		}
	}

}

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) {
	/* 991px */

	.c-content-testimonials-4 {

		.c-content {
			padding: 0 100px;
		}

		.owl-buttons {
			top: 135px;
		}

	}

}

@media (max-width: $screen-xs-max) {
	/* 766px */

	.c-content-testimonials-4 {

		.c-content {
			padding: 0;
		}

		.owl-buttons {
			visibility: hidden;
		}

	}

}

//END

//END: Testimonal 4
