// TITLE 1

.c-content-title-1 {
	
	> .c-line-center {
		width: 30px;
		height:3px;
		background-color:#e4002b;
		margin: 0 auto 30px auto;
	}

	> .c-line-right,
	> .c-line-left {
		width: 30px;
		height:3px;
		background-color:#32c5d2;
		margin: 0 0 30px 0;
	}

	> .c-line-right {
		margin: 0 0 30px auto;
	}

	> h3 { 
		font-size:28px;
		color: $general-head-font-color;
		font-weight:500;  
		margin:0 0 30px 0;

		> i {
			font-size: 22px; 
			color: lighten($general-head-font-color, 30%);
		}

		&.c-font-uppercase {
			font-size: 30px;
		}
	}

	> h1 {
		font-size:28px;
		color: $general-head-font-color;
		font-weight:500;
		margin:0 0 30px 0;

		> i {
			font-size: 22px;
			color: lighten($general-head-font-color, 30%);
		}

		&.c-font-uppercase {
			font-size: 30px;
		}
	}
	> h2 {
		font-size:28px;
		color: $general-head-font-color;
		font-weight:500;
		margin:0 0 30px 0;

		> i {
			font-size: 22px;
			color: lighten($general-head-font-color, 30%);
		}

		&.c-font-uppercase {
			font-size: 30px;
		}
	}
	
	> p {
		font-size:17px;
		font-weight: 300;
		color: #334047;
		margin: 10px 0;
		font-size: 16px;

		&.c-font-uppercase {
			font-size: 15px;
		}
	}

	> hr {
		margin-bottom: 30px;
	}

	&.c-title-md {

		> .c-line-left,
		> .c-line-center {
			margin-bottom: 20px;
		}	

		> h3 { 
			font-size:22px;
			margin-bottom: 22px;

			> i {
				font-size: 18px; 
				color: lighten($general-head-font-color, 30%);
			}

			&.c-font-uppercase {
				font-size: 20px;
			}
		}

		> p {
			font-size:17px;
			margin: 20px 0;

			&.c-font-uppercase {
				font-size: 14px;
			}
		}
	}

	&.c-title-sm {

		> .c-line-left,
		> .c-line-center {
			margin-bottom: 15px;
		}	

		> h3 { 
			font-size:18px;
			margin-bottom: 15px;

			> i {
				font-size: 16px; 
				color: lighten($general-head-font-color, 30%);
			}

			&.c-font-uppercase {
				font-size: 14px;
			}
		}

		> p {
			font-size: 16px;
			margin: 15px 0;

			&.c-font-uppercase {
				font-size: 14px;
			}
		}
	}
}

// END 