//	BEGIN: General Mode

.c-layout-revo-slider-3 {	
	
	.c-main-title-1 {
		line-height: 34px;
		display: inline-block;
		padding: 40px;
	}

	.c-main-title-2 {
		border: 1px solid rgba(#000, 0.1); 
		padding: 30px 50px;
		letter-spacing: 1px;
		line-height: 68px;
	}

	.c-action-btn.c-btn-border-2x {
		padding: 9px 24px 7px 24px;
		font-size: 16px;    
	}
}

@media (max-width: $screen-sm-max) { /* 991px */

	.c-layout-revo-slider-3 {

		.c-main-title-1 {
			padding: 20px;
			font-size: 18px;
			line-height: 25px;
		}

		.c-main-title-2 {
			padding: 20px 30px;
			line-height: 25px;
			font-size: 22px;
		}

		.c-action-btn {
			padding: 7px 22px 5px 22px;
			font-size: 14px;    
		}
	}
}

@media (max-width: $screen-xs-max) { /* 767px */

	.c-layout-revo-slider-3 {

		.c-main-title-1 {
			padding: 10px;
			font-size: 14px;  
			line-height: 18px;
			margin-left: 70px; 
		}

		.c-action-btn {
			margin-top: 50px;  
		}
	}
}