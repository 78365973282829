// Card 1
 
// BEGIN: General Mode

.c-content-feature-1 {

	padding-right: 40px;

	> h4 {
		font-size:18px;
		color: $general-head-font-color;
		margin-top:0;
		font-weight: 500;
		text-transform: uppercase;
	}

	p {
		font-size:18px;
		color: $general-font-color;
		text-align: center;
	}

	> h3 {
		font-size:21px;
		color: $general-head-font-color;
		font-weight:500;
		margin:30px 0 25px 0;
		text-align: center;
	}
}  

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

	.c-content-feature-1 {
		padding-right: 20px;
	}
}

// END