// Card 5, 6 & 7

// BEGIN: General Mode
.c-content-feature-10 {
	@include reset-padmar(); // call mixing reset default padding and margin
	width: 100%;
	padding:20px 0;

	h3{
		color: $general-head-font-color;
	}

	p{
		color:$general-font-color;
	}

	i{
		color:$general-head-font-color;
	}

	.c-bg-opacity-1{
		background-color: rgba(255,255,255,0.1);
		border:none;
	}

	.c-bg-opacity-2 {
		background-color: rgba(0,0,0,0.1);
	}

	.c-border-opacity{
		border: 1px solid rgba(0,0,0,0.1);
	}

	> .c-list {
		display:table;
		width:100%;
		padding:0;
		position:relative;

	  	> li {
	  		display: table-cell;
	    	width: 50%;
	    	padding: 0 5px 0 0;
	    	position:relative;

	    	> .c-card {
	    		padding: 40px;
	  			margin-right: 2px;

				> i{
					width:67px;
					height:67px;
					border-radius: 33.5px;
					text-align:center;
					padding:20px;
				}

				> .c-float-right {
					float:right;
					margin-left:40px;
				}

				> .c-float-left{
					float:left;
					margin-right:40px;
				}

				> .c-content-right{
					padding-right:107px;
				}

				> .c-content-left{
					padding-left:107px;
				}

				.c-content{

					>h3{
						margin:0 0 15px 0;
					}
				}
	    	}

			> .c-border-bottom {
				width:95%;
				height:1px;
				position:absolute;
				bottom:-1px;
				margin-left:2.5%;
			}

			> .c-mobile{
				display:none;
			}

	    	&:last-child  {
	    		padding: 0 0 0 5px;
	    	}

	  	}

	  	> .c-border-middle{
			height:90%;
			width:1px;
			position:absolute;
			margin-top:0.5%;
			top:0;
	  	}

	}

}

// END




// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-feature-10 {

		> .c-list{

			> li {				
				display: block;
				width: 100%;
				padding:0 15px 10px 15px;

				> .c-card {
					padding: 40px 20px;
					margin-right: 0;
					margin-bottom: 2px;

				}

				> .c-border-bottom {
					width:100%;
					height:1px;
					position: inherit;
					bottom:0;
					margin-left:0;
				}

				> .c-mobile{
					display:block;
				}

				&:last-child{
					padding: 0 15px;
				}
			}	

			> .c-border-middle{
				display:none;
			}

		}
	

	}

}

// END