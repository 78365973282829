// SHOP - PRODUCT DETAILS 3 & 4

.c-shop-product-details-4{

	.c-product-header{

		>.c-content-title-1{
			display:inline-block;

			> h3{
				margin-bottom: 0.5em;
			}
		}

		>.c-product-badge{
			display: inline-block;

			>.c-product-sale,
			>.c-product-new{
				padding:1em 0.9em;
				border-radius: 2em;
				margin:0 0 0 1em;
				color:#ffffff;
				display:inline-block;
			}

			>.c-product-new{
				background-color:#32c5d2;
			}

			>.c-product-sale{
				background-color:#eb5d68;
			}

		}

		>.c-product-review{
			float: right;
			padding-top: 0.5em;

			>.c-product-rating{
				display:inline-block;
				padding-right:1em;
				border-right:1px solid;
				border-color:#cccccc;
			}

			>.c-product-write-review{
				display: inline-block;
				padding-left:1em;
			}
		}

	}

	.c-product-gallery {
		overflow: hidden;
		clear:both;
		margin:40px 0;
		height:500px;

		.c-product-gallery-content{
			background: #fff;
			height: 500px;
			overflow: hidden;

			> .c-zoom{
				position: relative;
				height: 500px;
				overflow: hidden;
			}
		}  

		.c-product-gallery-thumbnail{

			.c-product-thumb{
				cursor: pointer;
				overflow:hidden;
				height:250px !important;
			}
		}


		img{
			width: 100%;
		}
	}

	&.c-opt-1{
		.c-product-gallery-content{
			height: 520px;
			overflow: hidden;

			> .c-zoom{
				height: 520px;
			} 
		}
	}

	.c-product-meta{	

		.c-product-price{
			font-size:32px;
			font-weight:600;
			margin-bottom:1em;
			text-align: right;
			padding:0.5em 0;
			float:right;
		}

		.c-product-short-desc{
			padding:1em;
		}

		.c-product-variant{

			select{
				border-color: #d0d7de;
    			padding: 6px;
			}
		}

		.c-product-meta-label{
			float:left;
			margin-right:1em;
			margin-top:0.25em;
		}

		.c-add-cart{
			display:block;
			float:right;
		}
	}

}

// END 

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-product-details-4{

		.c-product-header{

			>.c-content-title-1{
				display:block;
				text-align: center;
			}

			>.c-product-badge{
				display:block;
				text-align: center;
				margin-bottom: 0.5em;

				>.c-product-new,
				>.c-product-sale{
					margin:0;
				}
			}

			>.c-product-review{
				float:none;
				text-align: center;
			}
		}

		.c-product-gallery {
			height:1000px;
		}

		.c-product-meta{
			margin-top:1.5em;

			.c-product-short-desc{
				margin-bottom:1em;
			}

			.c-add-cart{
				float:none;
				margin:1em auto;
			}
		}

		
	}
}