// Header 
$layout_full_page:                                                              'no';  // yes or no
$header_class:																	'5';
$header_desktop-bg-color:														#2f353b;
$header_desktop-navbar-bg:														none;
$header_desktop_border:															0;
$header_min_desktop_border:														0;
$header_min_desktop_shadow:														none;

$header_zindex:			 														$zindex-navbar;
$header_transition: 															0.2s;
$header_height:                                                   				57px;  
$topbar_height:																	40px;
$header_on-scroll-height:                                         				57px;  

$header_desktop_brand-margin: 													0;  
$header_desktop_mega-menu-bg-color:												#2f353b;
$header_desktop_mega-menu-primary-link-padding: 								19px 25px 16px 25px; 
$header_desktop_mega-menu-btn-margin: 											12px 25px 7px 25px; 
$header_desktop_mega-menu-btn-icon-margin: 										1px 10px 0px 10px;
$header_desktop_mega-menu-quick-sidebar-toggler-padding:						22px 25px 14px 25px; 

$header_desktop_mega-menu-quick-search-close-icon-top:							28px;
$header_desktop_mega-menu-quick-search-close-icon-right:						22px;
$header_desktop_mega-menu-quick-search-form-control-padding:					10px 20px;

// Brand
$brand-link-font-color:  														#ebedf2;
$brand-link-font-color-on-hover:  												#ff6b57;

// Mega Menu
$mega-menu_desktop_primary-link-font-color:										#ffffff; 
$mega-menu_desktop_primary-link-font-color-on-hover:							#ffffff;
$mega-menu_desktop_primary-link-font-color-on-active:							#ffffff;  

$mega-menu_desktop_min-header-bg-color:											$header_desktop-bg-color;
$mega-menu_desktop_min-header-primary-link-font-color:							#ffffff;  

$header_mobile_brand-margin: 													0 0 0 0;   

@import  'theme/_header';

@media (min-width: $screen-md-min) { /* 992px */
	.c-layout-header.c-layout-header-5 {
		background: none !important;
		margin: 20px 0;

		//	Topbar  
		.c-navbar {

			.container-fluid {
				padding: 0 20px;
			}

			// Header brand
			.c-brand {
				margin: $header_desktop_brand-margin;
			}

			.c-navbar-wrapper {
				background: $header_desktop_mega-menu-bg-color; 
			}

			// Main markup
			.c-mega-menu {

				> .nav.navbar-nav {
				
					> li {
						// link
						> .c-link { 
							padding: $header_desktop_mega-menu-primary-link-padding;    
							min-height: $header_height;
						}
						// Quick sidebar toggler

						> .c-quick-sidebar-toggler {
							padding: $header_desktop_mega-menu-quick-sidebar-toggler-padding;

							.ie9 & {
								margin-bottom: -6px;
							}
							
							> .c-line { 
								width: 15px;
								display: block;
								height: 2px;
								padding: 0px;
								margin: 3px 0;  

								&:first-child {
									margin-top: 0px;  
								}

								.ie &:first-child {
									margin-top: 2px;  
								}
							}
						}

						// button
						> .c-btn {
							margin: $header_desktop_mega-menu-btn-margin;
						}

						// icon
						> .c-btn-icon {
							margin: $header_desktop_mega-menu-btn-icon-margin;
						}

						border-right: 1px solid lighten($header_desktop_mega-menu-bg-color, 3%);     

						&:last-child {
							border-right: 0;
						}    
					}
				}	      	
			}
		}   

		// Quick search
		.c-quick-search {
			> .form-control {
				padding: $header_desktop_mega-menu-quick-search-form-control-padding;
				height: $header_height;
			}

			> span {
				top: $header_desktop_mega-menu-quick-search-close-icon-top;
				right: $header_desktop_mega-menu-quick-search-close-icon-right;
			}
		}

	}
}

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-layout-header.c-layout-header-5 {

		.container-fluid {
			padding: 0;
		}

		//	Navbar
		.c-navbar {
			// Set brand component position
			.c-brand {
				margin: $header_mobile_brand-margin;

				> .c-hor-nav-toggler {
					margin-top: 14px; 
					margin-right: 15px;
				}

				> .c-cart-toggler {
					margin-top: 14px; 
					margin-right: 15px;
				}

				> .c-search-toggler {
					margin-top: 15px;
					margin-right: 15px;
				}
			}

			.c-mega-menu {
				padding: 30px 0 0 0;
			}
		}
	}

		// Fixed header for mobile mode
	.c-layout-header.c-layout-header-5 {     
		box-shadow: none;
		height: $header_height;

		.c-quick-search {
			.form-control {
				height: $header_height;
			}

			> span {
				top: 29px;
			}
		}

		&.c-mega-menu-shown {
			.c-mega-menu.c-mega-menu-dark-mobile {
				> .nav.navbar-nav > li {
					padding-left: 15px;
					padding-right: 15px;  
				}
			}
		}
	}	
}

// END