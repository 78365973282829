// SHOPPING ADVANCED SEARCH 1

.c-shop-advanced-search-1 {
	@include reset-padmar();

	.btn {
		& + .btn {
			margin-left: 5px;
		}
	}
}

// END

//MOBILE

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-shop-advanced-search-1 {
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */
	.c-shop-advanced-search-1 {
	}
}