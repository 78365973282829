// SHOP FORM 1

.c-shop-form-1 {
	@include reset-padmar();

	.c-shipping-price {
		margin-left: 30px;
	}

	.btn + .btn {
		margin-left: 5px;
	}
}

// END