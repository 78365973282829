// Parallax 1
 
// BEGIN: General Mode

.c-content-product-1 {
	@include reset-padmar(); // call mixing reset default padding and margin
	width: 100%;

	.row {
		position: relative;

		.c-media {
			> img {
				width:100%;
			}
		}

		.c-body {
			padding: 80px 0 20px 20px;
			
			> .c-row {
				padding:0 0 0 40px;

				> li{
					width:50%;
					display:inline-block;
					list-style: none;
					float:left;
					padding:0 50px 0 0 ;
					margin-bottom:35px;

					&:last-child{
						padding:0 0 0 50px;
					}

					> h4 {
						font-size:20px;
						color: $general-head-font-color;
						font-weight:600;
						margin-bottom:20px;
						text-transform: uppercase;
					}

					> p {
						color: $general-font-color;
						font-weight: 300;
					}
				}
			}

			> .btn {
				display:block;
				margin: 0 auto 60px auto;
				clear:both;
			}
		}		
	}
}

// END


// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-product-1 {

		.row {

			.c-media {
				> img {
					width:40%;
					margin: 0 auto;
					display:block;
				}
			}

			.c-body {
				padding: 30px 0 10px;

				> .c-row{
					padding:0;

					> li{
						width:100%;
						margin:40px 0;
						float:none;

						&:last-child{
							padding:0;
						}
					}
				}
			}
		}		
	}
}

// END