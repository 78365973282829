@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.c-layout-footer-6 {
	margin: 0;
	padding-top: 40px;

	.c-prefooter {
		padding: 35px 20px 40px;
		margin-bottom: 40px;
	}

	.c-content-title-1 {
		h3 {
			margin-bottom: 25px;
		}
	}

	.c-head {
		margin: 5px auto;

		.c-left {

			.socicon {
				margin-top: 3px;

				.socicon-btn {
					width: auto;
					height: auto;
					padding: 13px;

					& + .socicon-btn {
						margin-left: 5px;
					}
				}
			}
		}

		.c-right {
			float: right;

			.socicon-btn {
				margin: 0;
			}

			.c-title {
				float: left;
				margin: 20px 30px 0;
			}

			.c-icons {
				float: left;

				> .socicon-btn {
					width: auto;
					height: auto;
					padding: 13px;
					float: left;
					margin-left: -1px;
				}
			}
		}
	}

	.c-body {

		.c-twitter {
			width: 340px;
		}

		.c-links {
			float: left;
			padding: 0;
			width:50%;

			> li {
				list-style-type: none;
				line-height: 30px;
			}

		}  

		.c-address {
			margin: 0;
		}
	}

	.c-foot {
		padding-bottom: 5px;

		.c-text {
			margin: 0;
		}
	}

	.c-line {
		width: 100%;
		height: 1px;
		background-color: #e8f0f5;
		margin: 40px auto;
	}

	.c-postfooter {
		padding: 30px 0;

		.c-copyright {
			font-size: 15px;
			margin: 0;
		}

		.c-payments {
			margin-right: -5px;
		}

		.c-col {
			height: 38px;
			line-height: 38px;
		}
	}
}

@media (max-width: $screen-sm-max) {
	/* 991px */
	.c-layout-footer-6 {

		.c-head {

			.c-right {
				float: none;
				margin-top: 30px;

				> .c-title {
					display: none;
				}
			}
		}

		.c-body {

			.c-links, .c-twitter {
				margin-bottom: 40px;
			}

			.c-twitter {
				width: 100%;
			}
		}

		.c-foot {

			.c-text {
				margin-bottom: 40px;
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */
}
