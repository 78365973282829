// SHOP WISHLIST 1

.c-shop-wishlist-1 {
	@include reset-padmar();

	.c-row-item {
		padding: 35px 0;
	}

	.btn {
		& + .btn {
			margin-left: 5px;
		}
	}
}

// END