// List 1

.c-content-list-1 {
	padding:0;
	margin: 20px 0;

	> li{
		list-style:none;
		font-size:15px;
		font-weight: 300;
		color: $general-font-color;
		margin-bottom: 7px;
	}

	> li:before{
		content:'';
		width:21px;
		height:2px;
		float:left;
		margin:9px 15px 0 0;
		background: #ddd;
	}

	&.c-separator-dot {
		> li:before {
			content:'';
			display: inline-block;
		  	width: 8px;
		  	height: 8px;
		  	border-radius: 20px;
		  	background: #ddd;
		  	margin-right: 10px;
		  	margin-top: 8px;

		  	@include opacity(0.6);
		}

		&.c-square {
			> li:before {
				border-radius: 0;
			}
		}
	}
}

.c-content-iconlist-1 {
	padding:0;

	> li {
		list-style:none;
		display: inline-block;
		padding: 2px;

		&:last-child {
			padding-right: 0;
		}

		> a {
			border: 0;
		}

		i {
			position: relative;
			background: #f2f2f2;
			text-align:center;
			font-size:20px;
			color:#cccccc;
			padding: 13px;
			width:45px;
			height:45px;
		}

		&:hover {
			> a {
				border: 0;
			}
			
			i {
				border: 0;	
			}
		}
	}
}

.c-content-list-adjusted {
	padding: 0;
	margin: 0;
	list-style: none;

	> li {
		display: table-cell;
  		width: 1%;

  		padding-right: 5px;

  		&:last-child {
  			padding-right: 0;
  		}
	}
}