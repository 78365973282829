// Product 3

// BEGIN: General Mode

.c-content-product-3 {
	@include reset-padmar(); // call mixing reset default padding and margin

	.c-content {
		padding: 40px;

		.c-title {
			margin: 0 0 25px;
			font-weight: 500;
		}

		.c-price {
			margin-top: 10px;
			font-weight: lighter;
		}

		.btn {
			position: absolute;
			bottom: 40px;
		}
	}

	.c-border-padding {
		border: 1px solid rgba(255, 255, 255, 0.2);
		top: 20px;
		bottom: 20px;
		left: 20px;
		right: 20px;
		position: absolute;
		z-index: 1;
	}

}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-xs-max) {
	/* 767px */

	.c-content-product-3 {

		.c-border-padding {
		}

	}
}

// END