// TITLE 1

.c-content-title-3 {
	margin-bottom: 20px;
	
	> h3 { 
		font-size:28px;
		color: $general-head-font-color;
		font-weight:500;
		margin:0 0 15px 0;

		> i {
			font-size: 22px; 
			color: lighten($general-head-font-color, 30%);
		}

		&.c-font-uppercase {
			font-size: 30px;   
		}
	}
	
	> p {
		font-size:17px;
		font-weight: 400;
		color: $general-font-color;
		margin: 15px 0 0 0;

		&.c-font-uppercase {  
			font-size: 15px;
		}
	}

	padding-left: 20px;
	border-left: 4px solid  lighten($general-head-font-color, 30%);

	&.c-right {
		> h3 { 
			text-align: right;
		}

		> p {
			text-align: right;
		}

		padding-left: 0px;
		padding-right: 20px;
		border-left: 0;
		border-right: 4px solid  lighten($general-head-font-color, 30%);
	}

	&.c-title-md {

		> .c-line-left,
		> .c-line-center {
			margin-bottom: 15px;
		}	

		> h3 { 
			font-size:22px;
			margin-bottom: 22px;

			> i {
				font-size: 18px; 
				color: lighten($general-head-font-color, 30%);
			}

			&.c-font-uppercase {
				font-size: 20px;
			}
		}

		> p {
			font-size:17px;
			margin: 15px 0;

			&.c-font-uppercase {
				font-size: 14px;
			}
		}
	}

	&.c-title-sm {

		> .c-line-left,
		> .c-line-center {
			margin-bottom: 10px;
		}	

		> h3 { 
			font-size:18px;
			margin-bottom: 10px;

			> i {
				font-size: px; 
				color: lighten($general-head-font-color, 30%);
			}

			&.c-font-uppercase {
				font-size: 14px;
			}
		}

		> p {
			font-size: 16px;
			margin: 10px 0;

			&.c-font-uppercase {
				font-size: 14px;
			}
		}
	}
}

// END 

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */

.c-content-title-3 {
	margin-bottom: 10px;
	
	> h3 { 
		font-size:22px;
		margin-bottom: 10px;

		> i {
			font-size: 20px; 
		}

		&.c-font-uppercase {
			font-size: 20px;
		}
	}
	
	> p {
		font-size:17px;
		font-weight: 400;
		color: $general-font-color;
		margin: 15px 0 0 0;

		&.c-font-uppercase {
			font-size: 15px;
		}
	}

	padding-left: 20px;
	border-left: 4px solid  lighten($general-head-font-color, 30%);

	&.c-right {
		> h3 { 
			text-align: right;
		}

		> p {
			text-align: right;
		}

		padding-left: 0px;
		padding-right: 20px;
		border-left: 0;
		border-right: 4px solid  lighten($general-head-font-color, 30%);
	}

	&.c-title-md {

		> .c-line-left,
		> .c-line-center {
			margin-bottom: 15px;
		}	

		> h3 { 
			font-size:22px;
			margin-bottom: 22px;

			> i {
				font-size: 18px; 
				color: lighten($general-head-font-color, 30%);
			}

			&.c-font-uppercase {
				font-size: 20px;
			}
		}

		> p {
			font-size:17px;
			margin: 15px 0;

			&.c-font-uppercase {
				font-size: 14px;
			}
		}
	}

	&.c-title-sm {

		> .c-line-left,
		> .c-line-center {
			margin-bottom: 10px;
		}	

		> h3 { 
			font-size:18px;
			margin-bottom: 10px;

			> i {
				font-size: px; 
				color: lighten($general-head-font-color, 30%);
			}

			&.c-font-uppercase {
				font-size: 14px;
			}
		}

		> p {
			font-size: 16px;
			margin: 10px 0;

			&.c-font-uppercase {
				font-size: 14px;
			}
		}
	}
}

}

// END