// Bar 2
 
// BEGIN: General Mode

.c-content-bar-2 {
	@include reset-padmar(); // call mixing reset default padding and margin
	width: 100%;

	.c-content-title-1 {

		> h3 {
			color: $general-head-font-color;
			margin-bottom: 20px;
		}

		> p {
			color: $general-font-color;
			font-size:16px;
			margin: 0 0 20px 0;
			line-height: 25px;
		}
	}

	.c-content-v-center .c-body {
		padding:30px;
		
		h3 { 
			text-transform: uppercase;
			text-align:center;
			line-height:33px;
			font-size:21px;
			font-weight:500;
			margin:0;
		}
	}
}

// END

// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.c-content-bar-2 {

		.c-content-v-center {
			margin-top: 20px;

			.c-body {
				padding:20px;
			}
		}
	}
}

// END