// ANIMATED PROGRESS BAR

.c-progress-bar{

	.c-progress-bar-title{
		font-size:24px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 30px;
	}

	.c-progress-bar-container{

		.c-progress-bar-icon-line{
			display:inline-block;
			font-size: 30px;
		    width: 35px;
		    margin-right: 15px;
		}

		.c-progress-bar-line{
			position: relative;

			.progressbar-text{
				display: none;
			}

			&[data-display-value='true']{
				.progressbar-text{
					display: block;
				}
			}

			&[data-progress-bar="line"]{

				&.c-progress-bar-line-with-icon{
					margin: -37px 0 20px 50px;
				}

				.progressbar-text{
					text-align: right;
				}

				.c-progress-bar-label{
					font-size:16px;
					margin-bottom:0;
				}
			}

			&[data-progress-bar="circle"]{
				padding: 20px 50px;

				.progressbar-text,
				.c-progress-bar-icon{
					position: absolute;
				    top: 50%;
				    left: 50%;
				    transform: translateX(-50%) translateY(-50%);
				}

				.progressbar-text{
					text-align: center;
					font-size: 40px;
				}

				.c-progress-bar-icon{
					font-size:45px;
				}
			}

			&[data-progress-bar="semicircle"]{
				padding: 20px 50px;
				margin-bottom: 30px;

				.progressbar-text,
				.c-progress-bar-icon{
					position: absolute;
				    bottom:0;
				    left: 50%;
				    transform: translateX(-50%);
				}

				.progressbar-text{
					text-align: center;
					font-size: 40px;
				}

				.c-progress-bar-icon{
					font-size:45px;
				}
			}
		}
	}
}

@media (max-width: 1024px){ 
	.c-progress-bar{
		.c-progress-bar-container{
			.c-progress-bar-line{
				padding: 20px 10px !important;

				&[data-progress-bar="line"]{
					padding: 0 !important;
				}
			}
		}
	}	

}

@media (max-width: $screen-sm-max){ // 991px
	.c-progress-bar{

		.c-progress-bar-container{
			margin:0 20%;
			.c-progress-bar-line{
				padding: 20px 50px !important;
			}
		}
		.c-progress-bar-desc-container{
			margin:0 20% 30px 20%;
		}
	}
}

@media (max-width: $screen-xs-min){ // 480px;
	.c-progress-bar{

		.c-progress-bar-container{
			margin:0;
		}
		.c-progress-bar-desc-container{
			margin: 0 0 30px 0;
		}
	}

}