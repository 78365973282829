.c-content-person-1 {

	.c-body { 
		padding: 20px 0;

		.c-head {
			.c-name {
				display: inline-block;
				float: left;
				font-size: 20px;
		  		font-weight: 400;  
		  		color: $general-head-font-color; 
			}

			.c-socials {
				display: inline-block;
				float: right;
				list-style: none;
				padding: 0;
				margin: 4px 0 0 0;

				> li {
					padding: 0;
					margin: 0;
					display: inline-block;

					> a {
						&:hover {
							text-decoration: none;
						}

						> i {
							color: #a3aeb9;
							padding: 4px;
							font-size: 16px;
							border-radius: 100%;

							&:hover {
								color: #fff;
							}
						}
					}
				}
			}
		}

		.c-position {
			display: block;
			clear: both;
			margin-top: 0px;

			display: inline-block;
			float: left;
			font-size: 14px;
		  	font-weight: 400;
		  	text-transform: uppercase;
		  	color: lighten($general-head-font-color, 25%); 
		}

		p {
			display: block;
			clear: both;
			padding: 10px 0 0 0;
			color: $general-font-color; 
		}
	}

	&.c-option-2 {

		.c-body {
			padding: 30px;
			background: #fff;
			p {
				padding-bottom: 0;
			}
		}
	}

	&.c-bordered {
		border: 1px solid $general-border-color;
		padding: 20px;
	}

	&.c-shadow {
		box-shadow: 0px 3px 18px -5px rgba(0,0,0,0.3);
	}
}