// Base  

@import 'base/_page';

// Headers

@import 'headers/default/base/_header';
@import 'headers/default/base/_header-mobile';
@import 'headers/default/base/_mega-menu';
@import 'headers/default/base/_mega-menu-mobile';
@import 'headers/default/base/_topbar';
@import 'headers/default/base/_shop-cart';

@import 'headers/default/_header-default';
@import 'headers/default/_header-default-mobile';
@import 'headers/default/_header-dark-mobile';

@import 'headers/default/_header-2';   
@import 'headers/default/_header-3';   
@import 'headers/default/_header-4'; 
@import 'headers/default/_header-5';
@import 'headers/default/_header-6';             

@import 'headers/default/_mega-menu-dark';  
@import 'headers/default/_mega-menu-light';  
@import 'headers/default/_mega-menu-dark-mobile';
@import 'headers/default/_mega-menu-light-mobile';

@import 'headers/default/_topbar-dark';
@import 'headers/default/_topbar-light';


// Sliders

@import 'sliders/revo-slider/_base';
@import 'sliders/revo-slider/_slider-1'; 
@import 'sliders/revo-slider/_slider-2'; 
@import 'sliders/revo-slider/_slider-3';  
@import 'sliders/revo-slider/_slider-4';  
@import 'sliders/revo-slider/_slider-5';
@import 'sliders/revo-slider/_slider-6';
@import 'sliders/revo-slider/_slider-7'; 
@import 'sliders/revo-slider/_slider-8';
@import 'sliders/revo-slider/_slider-9';
@import 'sliders/revo-slider/_slider-10';
@import 'sliders/revo-slider/_slider-11';
@import 'sliders/revo-slider/_slider-12';  
@import 'sliders/revo-slider/_slider-13';  
@import 'sliders/revo-slider/_slider-14';

// Sidebars

@import 'sidebars/_quick-sidebar';  
@import 'sidebars/_sidebar-menu';  

// Breadcrumbs

@import 'breadcrumbs/_breadcrumbs-1'; 

// Footers

@import 'footers/_footer-1'; 
@import 'footers/_footer-2'; 
@import 'footers/_footer-3'; 
@import 'footers/_footer-4'; 
@import 'footers/_footer-6';
@import 'footers/_footer-7';
@import 'footers/_go2top';

