.c-content-client-logos-1 {
	padding-left: 20px;

	.c-logos {
		padding-left: 20px;

		.row {

			> div {
				padding: 0;
			}
		}
	}
			
	.c-logo {
		display:block;
		overflow: hidden;
	} 

	.c-logo-1 {
		border-right:1px solid #edeff0;
		border-bottom:1px solid #edeff0;
	}

	.c-logo-2 {
		border-right:1px solid #edeff0;
		border-bottom:1px solid #edeff0;
	}

	.c-logo-3 {
		border-bottom:1px solid #edeff0;
	}

	.c-logo-4 {
		border-right:1px solid #edeff0;
	}

	.c-logo-5 {
		border-right:1px solid #edeff0;
	}

	.c-img-pos{
		position: relative;
		left:50%;
		margin-left:-90px;
	}

}

// BEGIN: Mobile Mode

@media (max-width: $screen-md-max) { /* <= 1199px */

	.c-content-client-logos-1 {
						
		.c-logo-2{
			border-right:1px solid #edeff0;
			border-bottom:1px solid #edeff0;
		}

		.c-logo-3{
			border-right:none;
			border-bottom:1px solid #edeff0;
		}

		.c-logo-4{
			border-right:1px solid #edeff0;
			border-bottom:none;
		}
	
	}
}

@media (max-width: $screen-sm-max) { /* <= 991px */

	.c-content-client-logos-1 {

		.c-logos{
						
			.c-logo-2{
				border-right:none;
				border-bottom:1px solid #edeff0;
			}

			.c-logo-3{
				border-right:1px solid #edeff0;
				border-bottom:1px solid #edeff0;
			}

			.c-logo-4{
				border-right:none;
				border-bottom:1px solid #edeff0;
			}			
		}
	}
}


@media (max-width: $screen-sm-min) { /* >= 768px */

	.c-content-client-logos-1 {			

		.c-logo-2{
			border-right:none;
			border-bottom:1px solid #edeff0;
		}

		.c-logo-3{
			border-bottom:1px solid #edeff0;
			border-right:1px solid #edeff0;
		}

		.c-logo-4{
			border-right:none;
			border-bottom:1px solid #edeff0;
		}										
	}
}

@media (max-width: $screen-xs-max) { /* <= 767px */

	.c-content-client-logos-1 {		
		padding:0;
		margin-top:40px;	

		.c-logos{
			padding:0;
		}

		.c-logo-2{
			border-right:1px solid #edeff0;
			border-bottom:1px solid #edeff0;
		}

		.c-logo-3{
			border-bottom:1px solid #edeff0;
			border-right:none;
		}

		.c-logo-4{
			border-right:1px solid #edeff0;
			border-bottom:none;
		}
	}
}

@media (max-width: $screen-xs-min) { /* >= 480px */
	.c-content-client-logos-1 {

		.c-logo-2{
			border-right:none;
			border-bottom:1px solid #edeff0;
		}

		.c-logo-3{
			border-right:1px solid #edeff0;
			border-bottom:1px solid #edeff0;
		}

		.c-logo-4{
			border-right:none;
			border-bottom:1px solid #edeff0;
		}
	}
}

// END